import { Component, Renderer2 } from '@angular/core';
import { AuthService } from './services/auth.service';
import { environment } from '../environments/environment';
import { ScreenOrientation } from '@awesome-cordova-plugins/screen-orientation/ngx';
import { Platform } from '@ionic/angular';
import { StatusBar, Style } from '@capacitor/status-bar';
import { SwUpdate } from '@angular/service-worker';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  constructor(
    public updates: SwUpdate,
    private auth: AuthService,
    renderer: Renderer2,
    private screenOrientation: ScreenOrientation,
    public platform: Platform
  ) {
   // if (environment.project !== 'general') {
    //  renderer.setAttribute(document.body, 'color-theme', 'dark');
   // }

    // get current
    if (this.platform.is('hybrid'))
      this.screenOrientation.lock(this.screenOrientation.ORIENTATIONS.PORTRAIT);

    updates.available.subscribe(event => {
      console.log('current version is', event.current);
      console.log('available version is', event.available);
    });
    updates.activated.subscribe(event => {
      console.log('old version was', event.previous);
      console.log('new version is', event.current);
    });

    updates.available.subscribe(event => {
      updates.activateUpdate().then(() => {
        // https://stackoverflow.com/a/59793087/7638125
        // let hex = "⚽️".codePointAt(0).toString(16)
        // var r = confirm(`${String.fromCodePoint(0x1f680)} ENHORABUENA ${String.fromCodePoint(0x1f3c6)} UNA NUEVA VERSI${String.fromCodePoint(0x26bd)}N ${false ? window['version'] : ''} DISPONIBLE.`);
        document.location.reload();
        console.log("The app is updating right now");
      });
    });
  }
}
