import { Component, OnInit } from '@angular/core';
import { ModalController, PopoverController } from '@ionic/angular';
import { TermsComponent } from '../terms/terms.component';

@Component({
  selector: 'app-popover',
  templateUrl: './popover.component.html',
  styleUrls: ['./popover.component.scss'],
})
export class PopoverComponent implements OnInit {
  constructor(public popoverController: PopoverController,
    private modalController:ModalController) {}

  ngOnInit() {}
  logout() {
    this.popoverController.dismiss('logout');
  }
  async privacy(page) {
    const modal = await this.modalController.create({
      component: TermsComponent,
      cssClass: 'my-custom-class',
      componentProps: {
        initialPage: page,
      },
    });
    modal.onDidDismiss().then(async (data) => {
      console.log(data);
    });
    return await modal.present();
  }
}
