import { Component, OnInit, ViewChild } from '@angular/core';
import { Directory, Filesystem } from '@capacitor/filesystem';
import { ModalController, ToastController } from '@ionic/angular';
import {
  PDFDocumentProxy,
  PDFProgressData,
  PdfViewerComponent,
} from 'ng2-pdf-viewer';
import { TermsService } from 'src/app/services/terms.service';

import { environment } from 'src/environments/environment';
const URL = environment.URL;

@Component({
  selector: 'app-terms',
  templateUrl: './terms.component.html',
  styleUrls: ['./terms.component.scss'],
})
export class TermsComponent implements OnInit {
  initialPage: string;

  activeFile: any;
  terms: any = '';
  privacy: any= '';
  aboutUs: any = '';

  @ViewChild(PdfViewerComponent) private pdfComponent: PdfViewerComponent;
  constructor(
    private modalController: ModalController,

    private privacyService: TermsService
  ) {}

  ngOnInit() {
    this.getSettings();
  }
  dismiss(close) {
    this.modalController.dismiss({
      close: close,
    });
  }
  segmentChanged(ev: any) {
    this.initialPage = ev.detail.value;
  }

  getSettings() {
    this.privacyService.getTerms().subscribe(
      (res: any) => {
        this.terms = res.data.politica_servicio;
        this.privacy = res.data.politica_privacidad;
        this.aboutUs = res.data.about_us;
      },
      (err: any) => {
        console.log(err);
      }
    );
  }
}
